/* overrides for React Image Gallery to display as a lightbox*/

.lightbox {
    .image-gallery {
        height: 100%;
    }

    .image-gallery-content {
        display       : flex;
        flex-direction: column;
        height        : 100%;
    }

    .image-gallery-slide-wrapper {
        flex: 1 1 auto;
    }

    .image-gallery-swipe {
        display        : flex;
        align-items    : center;
        justify-content: center;
        height         : 100%;
    }

    .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: calc(100vh - 120px);
    }

    .image-gallery-content .image-gallery-slide .image-gallery-video {
        width     : 100%;
        max-height: calc(100vh - 120px);
        min-height: 70px;
        min-width: 70px;
        overflow: auto;
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-image {
        max-height: 60px;
        width     : auto;
        max-width : 100%;
    }

    .image-gallery-index {
        left : 0;
        right: unset;
        display: none;
    }

    .image-gallery-custom-controls {
        position  : absolute;
        top       : 0;
        right     : 0;
        z-index   : 4;
        background: rgba(0, 0, 0, .4);
    }
}
.pages-pdf {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.pages-pdf .react-pdf__Page{
    margin-bottom: 10px;
}
.pdfviewer__controls {
    position: absolute;
    bottom: 10px;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, 0);
}