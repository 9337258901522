@import 'react-big-calendar/lib/sass/styles';

.rbc-calendar {
  padding: 20px 0px 0px 0px;
  .home--custom-toolbar{
    padding: 0px 20px;
    row-gap: 4px;
  }
}
.rbc-time-content {
  display: none;
}
.rbc-day-bg + .rbc-day-bg, .rbc-month-view {
  border-color: #e9e9e9;
}
.rbc-time-view{
  z-index: 0;
}
.rbc-label{
  display: none;
}
.rbc-toolbar-label {
  font-weight: bold;
  text-transform: capitalize;
  font-size: 80%;
}
@media (max-width: 767px) {
  .rbc-toolbar{
    .rbc-btn-group {
      order: 2;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
  .rbc-show-more {
    font-size: 12px;
  }
  .rbc-month-row {
    flex-basis: auto !important;
    .rbc-row-content-scrollable {
      .rbc-row-content-scroll-container {
        min-height: 150px !important;
      }
    }
  }
}
.home-page-calendar {
  justify-content: space-between;
  .rbc-calendar{
    padding: 0;
    @media (min-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.rbc-month-view, .rbc-time-view {
  border-bottom: none;
  border-left: none;
  border-right: none;
    .rbc-header, .rbc-time-header-cell {
      text-transform: capitalize;
      border-left: 1px solid transparent !important;
      min-height: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
      font-size: 75%;
      .rbc-button-link{
        font-weight: bold;
      }
    }
  .rbc-off-range-bg {
    background-color: #f5f3f3;
  }
}